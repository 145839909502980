export function getOSNameVersion(device: {
  readonly os_name: string;
  readonly os_version: string;
}): string {
  const { os_name, os_version } = device;
  if (os_name === os_version) {
    return os_name;
  }

  return `${os_name} (${os_version})`.trim();
}
