import { ttl, makeQueryable } from '@ax/cache-and-dedupe-vue';
import { QueryByString } from '@ax/data-services-common/queries';
import { SoftwareSearchResponse } from '../models';
import { fetchSoftware } from '../clients';

/**
 * Describes a request for `Software`
 */
export class GetSoftware extends QueryByString<SoftwareSearchResponse> {
  type = 'GetSoftware';

  static make = (query: string) => new GetSoftware({ query });
}

export const { runQuery: runGetSoftware, useQuery: useGetSoftwareQuery } =
  makeQueryable(
    ({ query, showLoader }: GetSoftware) =>
      fetchSoftware(query, { loaderEnabled: showLoader }),
    {
      networkPolicy: ttl(60_000),
    },
  );
