import * as N from '@ax/function-utils/nullable';
import { Response, Nullable, failure, Success, Failure } from '@ax/type-utils';
import { computed, Ref, shallowRef } from '@vue/composition-api';
import { Hooks, tap, withHooks, Request } from '@ax/function-utils';
import { isNonNullable, isNullable } from '@ax/function-utils/nullable';

/**
 * Use to wrap an async request resulting in a `Response<A, E>` with various vue utilities. `makeMutatable` will return
 * a `useMutation` function which you can provide `Hooks<Input, A, E>` and an optional `input: Ref<Nullable<Input>>` as
 * props.
 *
 * When `useMutation` is called, you are returned a series of helpers like `isLoading`, `isSuccess`, `isFailure`,
 * `success`, and finally `mutate`. Variables to `mutate` can be provided via the optional `input` field on the props
 * provided to `useMutation` or to the `mutate` function directly. Variables supplied directly to `mutate` will take
 * precedence over variables within the `input` ref.
 *
 *
 * @param submit `(input: Input) => Promise<Response<A, E>>`
 * @returns `useMutation`
 */
export function makeMutatable<A, E, Input = void>(
  submit: Request.Request<Input, A, E>,
) {
  function useMutation(
    props: UseMutationWithRefInputProps<Input, A, E>,
  ): UseMutationReturnWithRefInput<Input, A, E>;
  function useMutation(
    props?: UseMutationPropsBase<Input, A, E>,
  ): UseMutationReturnWithRequiredInput<Input, A, E>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function useMutation(props: UseMutationPropsBase<Input, A, E> = {}): any {
    const { input: refInput, ...hooks } = props;

    const submitWithHooks = withHooks(submit, hooks);

    const currentResponse = shallowRef<MutationStatus<A, E>>(new Empty());

    const isLoading = computed(() => currentResponse.value.type === 'Loading');

    const isFailure = computed(() => currentResponse.value.type === 'Failure');

    const isSuccess = computed(() => currentResponse.value.type === 'Success');

    const error = computed(() =>
      currentResponse.value.type === 'Failure'
        ? currentResponse.value.error
        : undefined,
    );

    const success = computed(() =>
      currentResponse.value.type === 'Success'
        ? currentResponse.value.success
        : undefined,
    );

    const isDisabled = computed(() => {
      return (
        isLoading.value ||
        (isNonNullable(refInput) && isNullable(refInput.value))
      );
    });

    /**
     * Submit the mutation and update the mutation state.
     * @param input `Input` to be supplied to `submitWithHooks`
     * @returns `Response<A, E>`
     */
    function mutateWithInput(input: Input) {
      currentResponse.value = new Loading(currentResponse.value);
      return submitWithHooks(input).then(
        tap((response) => {
          currentResponse.value = response;
        }),
      );
    }

    /**
     * Submit a mutation. If `input` is provided it will take precedence over any
     * input provided in the ref to `useMutation`.
     * @param input `Input` optional
     * @returns
     */
    function mutate(input?: Input) {
      /**
       * Parameters directly supplied to `mutate` take precedence.
       */
      if (N.isNonNullable(input)) {
        return mutateWithInput(input);
      }
      /**
       * Then use the input available in the ref, if available
       */
      if (N.isNonNullable(refInput) && N.isNonNullable(refInput.value)) {
        return mutateWithInput(refInput.value);
      }
      return Promise.resolve(failure(new NoVariablesProvidedError()));
    }

    return {
      isDisabled,
      isLoading,
      isFailure,
      isSuccess,
      error,
      success,
      mutate,
    };
  }

  return useMutation;
}

/**
 * Represents the state of a mutation when nothing has happened yet.
 */
class Empty {
  readonly type = 'Empty';
}

/**
 * Indicates that the mutation is loading
 */
class Loading<A, E> {
  readonly type = 'Loading';

  constructor(readonly previous: MutationStatus<A, E>) {}
}

/**
 * The possible states for a mutation.
 */
type MutationStatus<A, E> = Empty | Loading<A, E> | Success<A> | Failure<E>;

export interface UseMutationPropsBase<Input, A, E>
  extends Hooks<[Input], A, E> {
  readonly input?: Ref<Nullable<Input>>;
}

export interface UseMutationWithRefInputProps<Input, A, E>
  extends UseMutationPropsBase<Input, A, E> {
  readonly input: Ref<Nullable<Input>>;
}

/**
 * Helpers to indicate the current state of the mutation
 */
export interface UseMutationReturnBase<A, E> {
  readonly isDisabled: Readonly<Ref<boolean>>;
  readonly isLoading: Readonly<Ref<boolean>>;
  readonly isFailure: Readonly<Ref<boolean>>;
  readonly isSuccess: Readonly<Ref<boolean>>;
  readonly error: Readonly<Ref<Nullable<E>>>;
  readonly success: Readonly<Ref<Nullable<A>>>;
}

export class NoVariablesProvidedError {
  readonly type = 'NoVariablesProvidedError';

  readonly message = 'No variables were provided and the mutation was not run';
}

/**
 * If a `Ref<Input>` is supplied to `useMutation`, then the `input`
 * on `mutate` is optional.
 */
export interface UseMutationReturnWithRefInput<Input, A, E>
  extends UseMutationReturnBase<A, E> {
  readonly mutate: (
    input?: Input,
  ) => Promise<Response<A, E | NoVariablesProvidedError>>;
}

/**
 * When no `Ref<Input>` is supplied to `useMutation`, the `input` parameter
 * is required on `mutate`.
 */
export interface UseMutationReturnWithRequiredInput<Input, A, E>
  extends UseMutationReturnBase<A, E> {
  readonly mutate: (input: Input) => Promise<Response<A, E>>;
}
