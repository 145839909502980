import { BespokeHTTPClient } from '@ax/data-services-common/client';
import { ConsoleAxiosRequestConfig } from '@ax/api-clients-common';
import { MintJwtInput } from '../models';

const MINT_JWT_ENDPOINT = '/jwt';

const REFRESH_JWT_ENDPOINT = '/jwt/refresh';

export function mintJwt(
  queryParams?: MintJwtInput,
  requestConfig: ConsoleAxiosRequestConfig = {},
): Promise<string> {
  return BespokeHTTPClient.post(MINT_JWT_ENDPOINT, undefined, {
    ...(queryParams
      ? {
          params: { o: queryParams.orgId, device_uuid: queryParams.deviceUuid },
        }
      : {}),
    ...requestConfig,
  }).then(({ data }) => data.token);
}

/**
 *
 * @param currentToken The current token to refresh
 * @returns a new, refreshed token
 */
export function refreshJwt(
  currentToken: string,
  requestConfig: ConsoleAxiosRequestConfig = { loaderEnabled: false },
) {
  return BespokeHTTPClient.post<{ readonly token: string }>(
    REFRESH_JWT_ENDPOINT,
    undefined,
    {
      ...requestConfig,
      headers: {
        Authorization: `Bearer ${currentToken}`,
      },
    },
  ).then((response) => response.data.token);
}
