import { pipe, Nullable } from '@ax/function-utils';
import * as REF from '@ax/vue-utils/ref';
import { Ref } from '@vue/composition-api';
import { GetPolicySoftware, useGetPolicySoftwareQuery } from '../queries';
import { useFormattedSoftwareQueryResponse } from './use-formatted-software-query-response';

/**
 * Takes in a `Ref<string | undefined>` representing the current query and returns a
 * `FormattedSoftwareQueryResponse` which contains fields like `totalPackages`,
 * `packages`, `error`, `isLoading`, `isRefreshing`, etc.
 *
 * @example
 * const currentQuery = shallowRef('q=1&...');
 *
 * const {
 *  packages, // Ref<readonly SoftwarePackage[]>
 *  totalPackages, // Ref<number>
 *  isLoading, // Ref<boolean>
 *  error, // Ref<ShapeOfError<GetPolicySoftware> | undefined>
 *  isFailure, // Ref<boolean>
 *  isRefreshing, // Ref<boolean>
 *  exportCsvUrl // Ref<string>
 * } = usePolicySoftware(currentQuery)
 * @param query `Ref<string | undefined>`
 * @returns `FormattedSoftwareQueryResponse`
 */
export function usePolicySoftware(query: Ref<string | undefined>) {
  return pipe(
    query,
    REF.map(Nullable.map(GetPolicySoftware.make)),
    useGetPolicySoftwareQuery,
    useFormattedSoftwareQueryResponse,
  );
}
