import { consoleEnv } from '@ax/console-env';
import { DEVICES_ENDPOINT } from '../clients';

/**
 * Get the csv download url based on the given `query`
 * @param query `string`
 * @returns `string`
 */
export function getExportCsvUrl(query?: string) {
  return `${consoleEnv.API_BASE_URL}${DEVICES_ENDPOINT}/csv${
    query ? `?${query}` : ''
  }`;
}
