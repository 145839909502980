import { makeQueryable, Query, success } from '@ax/cache-and-dedupe-vue';
import { fetchMdmAccount, FetchMDMAccountError } from '../client';
import { MDMAccount } from '../models/mdm-account';

/**
 * Describes a request for `MDMAccountInfo` related to a given `orgId`
 */
export class GetMDMAccountInfoByOrgId extends Query<
  { readonly orgId: number },
  MDMAccount,
  FetchMDMAccountError
> {
  readonly type = 'GetMDMAccountInfoByOrgId';

  static make = (orgId: number) => new GetMDMAccountInfoByOrgId({ orgId });
}

export const {
  useQuery: useGetMDMAccountByOrgId,
  runQuery: runGetMDMAccountAccountByOrgId,
  cache,
} = makeQueryable((query: GetMDMAccountInfoByOrgId) =>
  fetchMdmAccount(query.orgId),
);

export function updateAccountByOrgId(orgId: number, account: MDMAccount) {
  cache.set(GetMDMAccountInfoByOrgId.make(orgId), success(account));
}
