import { ReactiveQueryResponse } from '@ax/cache-and-dedupe-vue/queryable/make-reactive-query-response';
import { Nullable } from '@ax/function-utils';
import { updateWhen_ } from '@ax/vue-utils/ref';
import { GetPolicySoftware, GetSoftware } from '../queries';
import {
  ReactiveFormattedSoftwareResponse,
  useFormattedSoftwareResponse,
} from './use-formatted-software-response';

export interface FormattedSoftwareQueryResponse
  extends ReactiveQueryResponse<GetSoftware | GetPolicySoftware>,
    ReactiveFormattedSoftwareResponse {}

/**
 * Formats the `data` object in `ReactiveQueryResponse<GetSoftware | GetPolicySoftware>` into
 * something more readily consumable by creating `totalPackages` and `packages` refs.
 * @param response
 * @returns
 */
export function useFormattedSoftwareQueryResponse(
  response: ReactiveQueryResponse<GetSoftware | GetPolicySoftware>,
): FormattedSoftwareQueryResponse {
  const { data, ...remaining } = response;
  return {
    data,
    ...useFormattedSoftwareResponse(updateWhen_(data, Nullable.isNonNullable)),
    ...remaining,
  };
}
