import { ConsoleAxiosRequestConfig } from '@ax/api-clients-common';
import { AxiosResponse } from 'axios';
import { BaseHTTPClient } from '@ax/data-services-common/client';
import {
  BillingOrganization,
  Rates,
  LegacyRates,
  CreditCard,
  Subscription,
  Invoice,
  BillingAddress,
  SubscriptionPayload,
} from '../models/billing';

const ORG_ENDPOINT = '/orgs/self';
const RATES_ENDPOINT = `${ORG_ENDPOINT}/rate`;
const SUBSCRIPTION_ENDPOINT = `${ORG_ENDPOINT}/subscription`;
const ADDRESSES_ENDPOINT = `${ORG_ENDPOINT}/addresses`;
const CREDIT_CARD_ENDPOINT = `${ORG_ENDPOINT}/billing`;
const INVOICES_ENDPOINT = `${ORG_ENDPOINT}/invoices`;

export function fetchOrganization(
  orgId: string,
  requestConfig: ConsoleAxiosRequestConfig = {},
) {
  return BaseHTTPClient.get<BillingOrganization>(ORG_ENDPOINT, {
    ...requestConfig,
    params: { o: orgId, internal: 1 },
  });
}

export const putBillingAddress = (
  orgId: string | number,
  address: BillingAddress,
): Promise<AxiosResponse> => {
  return BaseHTTPClient.put(ADDRESSES_ENDPOINT, address, {
    params: { o: orgId, internal: 1 },
  }).then((response) => response.data);
};

export function fetchRates(
  orgId: string,
  requestConfig: ConsoleAxiosRequestConfig = {},
) {
  return BaseHTTPClient.get<Rates | LegacyRates>(RATES_ENDPOINT, {
    ...requestConfig,
    params: { o: orgId, internal: 1 },
  });
}

export function fetchSubscriptions(
  orgId: string,
  requestConfig: ConsoleAxiosRequestConfig = {},
) {
  return BaseHTTPClient.get<readonly Subscription[]>(SUBSCRIPTION_ENDPOINT, {
    ...requestConfig,
    params: { o: orgId, internal: 1, active: 1 },
  });
}

export const postSubscription = (
  orgId: string | number,
  payload: SubscriptionPayload,
): Promise<AxiosResponse> => {
  return BaseHTTPClient.post(SUBSCRIPTION_ENDPOINT, payload, {
    params: { o: orgId, internal: 1 },
  }).then((response) => response.data);
};

export const putSubscription = (
  orgId: string | number,
  payload: Subscription,
): Promise<AxiosResponse> => {
  return BaseHTTPClient.put(
    `${ORG_ENDPOINT}/subscription/${payload.id}`,
    payload,
    {
      params: { o: orgId, internal: 1 },
    },
  ).then((response) => response.data);
};

export function fetchCreditCard(
  orgId: string,
  requestConfig: ConsoleAxiosRequestConfig = {},
) {
  return BaseHTTPClient.get<CreditCard>(CREDIT_CARD_ENDPOINT, {
    ...requestConfig,
    params: { o: orgId, internal: 1 },
  });
}

export const putCreditCard = (
  orgId: string | number,
  stripe_token: string,
): Promise<AxiosResponse> => {
  return BaseHTTPClient.put(
    CREDIT_CARD_ENDPOINT,
    {
      stripe_token,
    },
    { params: { o: orgId, internal: 1 } },
  ).then((response) => response.data);
};

export function fetchInvoices(
  orgId: string,
  requestConfig: ConsoleAxiosRequestConfig = {},
) {
  return BaseHTTPClient.get<readonly Invoice[]>(INVOICES_ENDPOINT, {
    ...requestConfig,
    params: { o: orgId, internal: 1 },
  });
}
