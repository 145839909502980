import {
  ApiClientError,
  AxiosResponseError,
} from '@ax/api-clients-common/models';
import { UnknownFetchError } from '@ax/cache-and-dedupe-vue';
import { Request } from '@ax/function-utils';
import { failure, success, Response } from '@ax/type-utils';
import { AxiosInstance } from 'axios';
import { ConfigLoadingError } from '@ax/data-services-config';
import {
  UploadAPNSCertificateSuccess,
  UploadAPNSCertificateAPIError,
} from '../models';
import { fetchMDMClient } from './fetch-mdm-client';

export interface UploadAPNSCertificateInput {
  readonly accountId: string;
  readonly certificate: File;
}

export type UploadAPNSCertificateResponse = Response<
  UploadAPNSCertificateSuccess,
  UploadAPNSCertificateError
>;

export type UploadAPNSCertificateError =
  | UnknownFetchError
  | ApiClientError<AxiosResponseError>
  | ConfigLoadingError
  | UploadAPNSCertificateAPIError;

/**
 * Upload an APNS certificate to the specified account
 * @param accountId The id of the macOS patching account
 * @param file The certificate file to be uploaded to the account
 * */
export function uploadAPNSCertificate(
  input: UploadAPNSCertificateInput,
): Promise<UploadAPNSCertificateResponse> {
  return fetchMDMClient().then(
    Request.flatMap(updateAPNSCertificateWithClient(input)),
  );
}

export function updateAPNSCertificateWithClient(
  input: UploadAPNSCertificateInput,
) {
  return (client: AxiosInstance) => {
    const formData = new FormData();
    formData.append('certificate', input.certificate);
    return client
      .put(`/accounts/${input.accountId}/apns`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(({ data }) => success(new UploadAPNSCertificateSuccess(data)))
      .catch((error) => failure(new UploadAPNSCertificateAPIError(error)));
  };
}
