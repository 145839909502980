import { AxiosResponse } from 'axios';
import {
  PaginatedResponse,
  GlobalPaginationMetadata,
} from '@ax/api-clients-common/models';
import {
  AccountRbacRole,
  AccountUser,
  AccountUserCreate,
  ZoneAssignmentExpanded,
  AccountInvitation,
  AccountUserUpdate,
  AccountUserWithZones,
} from '../models';
import {
  getPublicAccountHttpClient,
  getBespokeAccountHttpClient,
} from '../index';

const USERS_ENDPOINT = '/users';
const INVITATIONS_ENDPOINT = '/invitations';

// https://automox-ll.stoplight.io/docs/global-view/branches/Phase%201/account_management.yaml/paths/~1api~1accounts~1%7BaccountId%7D~1users~1%7BuserId%7D/get
export function getUser(userId: string): Promise<AccountUser> {
  return getPublicAccountHttpClient()
    .get(`${USERS_ENDPOINT}/${userId}`)
    .then(({ data }) => data);
}

// https://automox-ll.stoplight.io/docs/global-view/branches/Phase%201/account_management.yaml/paths/~1accounts~1%7BaccountId%7D~1users/get
export function getUsers({
  accountRbacRole,
  limit,
  page,
}: {
  limit?: number;
  page?: number;
  accountRbacRole?: AccountRbacRole;
} = {}): Promise<
  PaginatedResponse<AccountUserWithZones, GlobalPaginationMetadata>
> {
  return getBespokeAccountHttpClient()
    .get(USERS_ENDPOINT, {
      params: {
        ...(accountRbacRole && { 'account_rbac_role:equals': accountRbacRole }),
        ...(limit && { limit }),
        ...(page && { page }),
      },
    })
    .then(({ data }) => data);
}

// https://automox-ll.stoplight.io/docs/global-view/branches/Phase%201/account_management.yaml/paths/~1api~1accounts~1%7BaccountId%7D~1invitations/post
export function createUser(
  payload: AccountUserCreate,
): Promise<AccountInvitation> {
  return getPublicAccountHttpClient()
    .post(INVITATIONS_ENDPOINT, payload)
    .then(({ data }) => data);
}

// https://automox-ll.stoplight.io/docs/global-view/branches/Phase%201/account_management.yaml/paths/~1api~1accounts~1%7BaccountId%7D~1users~1%7BuserId%7D~1zones/get
export function getUserZoneAssignments(
  userId: string,
  query = '',
): Promise<
  PaginatedResponse<ZoneAssignmentExpanded, GlobalPaginationMetadata>
> {
  return getPublicAccountHttpClient()
    .get(`${USERS_ENDPOINT}/${userId}/zones?${query}`)
    .then(({ data }) => data);
}

// https://automox-ll.stoplight.io/docs/global-view/branches/Phase%201/account_management.yaml/paths/~1accounts~1%7BaccountId%7D~1users~1%7BuserId%7D/post
export function updateUserRbac(
  userId: string,
  payload: AccountUserUpdate,
): Promise<AxiosResponse> {
  return getBespokeAccountHttpClient().post(
    `${USERS_ENDPOINT}/${userId}`,
    payload,
  );
}

// https://automox-ll.stoplight.io/docs/global-view/branches/Phase%201/account_management.yaml/paths/~1api~1accounts~1%7BaccountId%7D~1users~1%7BuserId%7D/delete
export function deleteUser(userId: string): Promise<AxiosResponse> {
  return getPublicAccountHttpClient().delete(`${USERS_ENDPOINT}/${userId}`);
}
