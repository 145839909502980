import { CacheAndNetwork, makeQueryable } from '@ax/cache-and-dedupe-vue';
import { success } from '@ax/type-utils';
import {
  defaultCatchHandler,
  QueryByOrgId,
} from '@ax/data-services-common/queries';
import {
  BillingOrganization,
  Rates,
  LegacyRates,
  Subscription,
  CreditCard,
  Invoice,
} from '../models/billing';
import {
  fetchOrganization,
  fetchRates,
  fetchCreditCard,
  fetchSubscriptions,
  fetchInvoices,
} from '../clients/billing.client';

/**
 * The query for getting an organization's billing details
 */
export class GetBillingOrgByOrgId extends QueryByOrgId<BillingOrganization> {
  type = 'GetBillingOrgByOrgId';
}

export const {
  runQuery: runGetBillingOrgByOrgId,
  useQuery: useGetBillingOrgByOrgIdQuery,
} = makeQueryable(
  (query: GetBillingOrgByOrgId) => {
    return fetchOrganization(String(query.orgId), {
      loaderEnabled: query.showLoader,
    })
      .then(({ data }) => success(data))
      .catch(defaultCatchHandler);
  },
  {
    networkPolicy: CacheAndNetwork,
    /**
     * As of 05/09/2022 https://app.datadoghq.com/dash/integration/rum_resources?tpl_var_resourcePath=%22%2Fapi%2Forgs%2Fself%22&from_ts=1649436935100&to_ts=1652115335100&live=true
     * The P90 reponse time was < 1s and it never eclipsed 5s in the past month.
     */
    timeout: 10_000,
  },
);

/**
 * The query for getting an organization's billing rates
 */
export class GetBillingRatesByOrgId extends QueryByOrgId<Rates | LegacyRates> {
  type = 'GetBillingRatesByOrgId';
}

export const {
  runQuery: runGetBillingRatesByOrgId,
  useQuery: useGetBillingRatesByOrgIdQuery,
} = makeQueryable(
  (query: GetBillingRatesByOrgId) => {
    return fetchRates(String(query.orgId), {
      loaderEnabled: query.showLoader,
    })
      .then(({ data }) => success(data))
      .catch(defaultCatchHandler);
  },
  {
    networkPolicy: CacheAndNetwork,
    /**
     * As of 05/09/2022 https://app.datadoghq.com/dash/integration/rum_resources?tpl_var_resourcePath=%22%2Fapi%2Forgs%2Fself%2Frate%22&from_ts=1649436935100&to_ts=1652115335100&live=true
     * The P90 reponse time was < 1s and it never eclipsed 5s in the past month.
     */
    timeout: 10_000,
  },
);

/**
 * The query for getting an organization's billing rates
 */
export class GetSubscriptionsByOrgId extends QueryByOrgId<
  readonly Subscription[]
> {
  type = 'GetSubscriptionsByOrgId';
}

export const {
  runQuery: runGetSubscriptionsByOrgId,
  useQuery: useGetSubscriptionsByOrgIdQuery,
} = makeQueryable(
  (query: GetSubscriptionsByOrgId) => {
    return fetchSubscriptions(String(query.orgId), {
      loaderEnabled: query.showLoader,
    })
      .then(({ data }) => success(data))
      .catch(defaultCatchHandler);
  },
  {
    networkPolicy: CacheAndNetwork,
    /**
     * As of 05/09/2022 https://app.datadoghq.com/dash/integration/rum_resources?tpl_var_resourcePath=%22%2Fapi%2Forgs%2Fself%2Fsubscription%22&from_ts=1649436935100&to_ts=1652115335100&live=true
     * The P90 reponse time was < 1s and it never eclipsed 5s in the past month.
     */
    timeout: 10_000,
  },
);

/**
 * The query for getting an organization's saved credit card information
 */
export class GetCreditCardByOrgId extends QueryByOrgId<CreditCard> {
  type = 'GetCreditCardByOrgId';
}

export const {
  runQuery: runGetCreditCardByOrgId,
  useQuery: useGetCreditCardByOrgIdQuery,
} = makeQueryable(
  (query: GetCreditCardByOrgId) => {
    return fetchCreditCard(String(query.orgId), {
      loaderEnabled: query.showLoader,
    })
      .then(({ data }) => success(data))
      .catch(defaultCatchHandler);
  },
  {
    networkPolicy: CacheAndNetwork,
    /**
     * As of 05/09/2022 https://app.datadoghq.com/dash/integration/rum_resources?tpl_var_resourcePath=%22%2Fapi%2Forgs%2Fself%2Fbilling%22&from_ts=1649436935100&to_ts=1652115335100&live=true
     * The P90 reponse time was < 1s and it never eclipsed 5s in the past month.
     */
    timeout: 10_000,
  },
);

/**
 * The query for getting an organization's saved credit card information
 */
export class GetInvoicesByOrgId extends QueryByOrgId<readonly Invoice[]> {
  type = 'GetInvoicesByOrgId';
}

export const {
  runQuery: runGetInvoicesByOrgId,
  useQuery: useGetInvoicesByOrgIdQuery,
} = makeQueryable(
  (query: GetInvoicesByOrgId) => {
    return fetchInvoices(String(query.orgId), {
      loaderEnabled: query.showLoader,
    })
      .then(({ data }) => success(data))
      .catch(defaultCatchHandler);
  },
  {
    networkPolicy: CacheAndNetwork,
    timeout: 10_000,
  },
);
