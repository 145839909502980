import { makeQueryable, ttl } from '@ax/cache-and-dedupe-vue';
import { QueryByString } from '@ax/data-services-common/queries';
import { fetchPolicySoftware } from '../clients';
import { SoftwareSearchResponse } from '../models/software';

/**
 * Describes a request for `Software` specific to a given policy.
 */
export class GetPolicySoftware extends QueryByString<SoftwareSearchResponse> {
  type = 'GetPolicySoftware';

  static make = (query: string) => new GetPolicySoftware({ query });
}

export const {
  runQuery: runGetPolicySoftware,
  useQuery: useGetPolicySoftwareQuery,
} = makeQueryable(
  ({ query, showLoader }) =>
    fetchPolicySoftware(query, { loaderEnabled: showLoader }),
  {
    networkPolicy: ttl(60_000),
  },
);
