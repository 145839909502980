import { DeviceSoftwareDTO } from './device-software-dto';

export interface GroupedDeviceSoftware extends DeviceSoftwareDTO {
  readonly data: DeviceSoftwareDTO;

  readonly current_version?: string;

  readonly latest_version?: string;

  readonly needs_reboot?: boolean;

  readonly package_name?: string;

  readonly status?: Status | string;
}

export enum Status {
  ignored = 'Ignored',
  deferredUntil = 'Deferred until',
  awaitingUpdate = 'Update Available',
  installed = 'Installed',
}
