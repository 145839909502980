import { failure, success } from '@ax/type-utils';
import {
  isSuccess as statusIsSuccess,
  makeQueryable,
  Query,
  QueryStatusCacheEntry,
  CacheFirst,
} from '@ax/cache-and-dedupe-vue';
import * as QS from '@ax/cache-and-dedupe-core/query-status';
import { isObjectEmpty } from '@ax/object-utils';
import { tap } from '@ax/function-utils';
import { jwtIsExpired, shouldRefresh } from '../business-logic';
import { mintJwt, refreshJwt } from '../clients';
import { JwtMintingError, JwtRefreshError } from '../models';
import { MintJwtInput } from '../models/mint-jwt-input';

/**
 * A string in the form of a Jwt token
 */
export type Token = string;

/**
 * The possible errors for `GetJwt`
 */
export type GetJwtError = JwtMintingError | JwtRefreshError;

/**
 * Represents a request for a new jwt. When a `GetJwt` request is submitted
 * to `useJwtQuery` below, it will return reactive utilities with a success
 * of type `Token` and a failure of type `GetJwtError`.
 */
export class GetJwt extends Query<MintJwtInput, Token, GetJwtError> {
  readonly type = 'GetJWT';

  static make = (args: MintJwtInput) => new GetJwt(args);
}

const {
  /**
   *
   * @example
   * const {
   *  data, // Ref<string | undefined>
   *  isLoading, // Ref<boolean>
   *  isFailure, // Ref<boolean>
   *  isRefreshing, // Ref<boolean>
   *  isSuccess, // Ref<boolean>,
   *  error, // Ref<GetJwtError | undefined>
   * } = useJwtQuery(new GetJwt({})) // can also pass in a computed
   */
  useQuery: useJwtQuery,
  /**
   * Use to force run a given query (bypass the cache), but still update the cache on any responses
   */
  runQuery,
  /**
   * Effectively a `Map<GetJwt, GetJwtResponses>`
   */
  cache: jwtCache,
} = makeQueryable(mintJwtAsResponse, {
  /**
   * If a token is present in the cache, we don't need to request a new one. The
   * `useJwt` composition will handle proper refreshes.
   */
  networkPolicy: CacheFirst,
});

/**
 * This function calls the `mintJwt` function exposed by `jwt-client` and wraps its success
 * and errors into a `Response<Token, JwtMintingError`
 * @param query `GetJwt`
 * @returns `Response<Token, JwtMintingError>`
 */
function mintJwtAsResponse(query: GetJwt) {
  return mintJwt(isObjectEmpty(query._args) ? undefined : query)
    .then((token) => success(token))
    .catch((e) => failure(new JwtMintingError(e)));
}

/**
 * Since refreshing a token is a different process than minting, we are overriding the `runQuery`
 * functionality here so that it hits the refresh endpoint when appopriate.
 * @param query
 * @returns
 */
export async function runJwtQuery(query: GetJwt) {
  const cacheEntry = jwtCache.getOrCreate(query);
  /**
   * If a valid, unexpired token exists for the given query, refresh it. Otherwise
   * mint a new one.
   */
  return statusIsSuccess(cacheEntry.status) &&
    shouldRefresh(cacheEntry.status.data)
    ? refreshJwtQuery(cacheEntry.status.data, cacheEntry)
    : runQuery(query);
}

/**
 * Use to make a request for a `Jwt`. If there is a valid token already cached, it will
 * be returned. If not, a request to mint a new JWT will be submitted. This function will
 * also make a request to refresh a token if it is close to expiring.
 * @param input `MintJwtInput`
 * @returns `Promise<Success<Token> | Failure<GetJwtError>>`
 */
export function fetchJwt(input: MintJwtInput = {}) {
  const query = GetJwt.make(input);
  const cacheEntry = jwtCache.getOrCreate(query);

  if (
    statusIsSuccess(cacheEntry.status) &&
    !jwtIsExpired(cacheEntry.status.data)
  ) {
    const token = cacheEntry.status.data;
    refreshJwtOptimisticly(token, cacheEntry);
    return Promise.resolve(success(token));
  }

  return mintJwtAsResponse(query).then(
    tap((response) => {
      cacheEntry.status = QS.fromResponse(response);
    }),
  );
}

/**
 * Refreshes a token optimistically if the token is within a `shouldRefresh` window.
 */
function refreshJwtOptimisticly(
  token: string,
  cacheEntry: QueryStatusCacheEntry<GetJwt>,
) {
  if (shouldRefresh(token)) {
    refreshJwtQuery(token, cacheEntry);
  }
}

/**
 * Refresh a token and update the corresponding cache entry.
 * @param token
 * @param cacheEntry
 * @returns
 */
function refreshJwtQuery(
  token: string,
  cacheEntry: QueryStatusCacheEntry<GetJwt>,
) {
  return refreshJwt(token)
    .then((token) => {
      return success(token);
    })
    .catch((e) => {
      return failure(new JwtRefreshError(e));
    })
    .then(
      tap((response) => {
        cacheEntry.status = QS.fromResponse(response);
      }),
    );
}

export { useJwtQuery, jwtCache };
