import { Copyable, UnknownRecord } from '@ax/object-utils';
import {
  Timestamp,
  daysExposed as formatDaysExposed,
  formatDate,
} from '@ax/date-time-utils';
import { getOSNameVersion } from '@ax/data-services-devices/business-logic';

export interface SoftwarePackageDTO {
  readonly package_id: number;

  readonly agent_severity: number | null;

  readonly create_time: Timestamp;

  readonly cve_score: number | null;

  readonly cves: UnknownRecord;

  readonly devices_needed: number;

  readonly display_name: string;

  readonly group_ignored: boolean;

  readonly is_managed: boolean;

  readonly name: string;

  readonly os_family_name: string;

  readonly os_name: string;

  readonly os_version: string;

  readonly os_version_id: string;

  readonly package_version: string;

  readonly package_version_id: number;

  readonly patch_installed: number;

  readonly requires_reboot: boolean;

  readonly severity: string | null;
}

export class SoftwarePackage extends Copyable<SoftwarePackageDTO> {
  /**
   * The below values are used in various places in the codebase. Calculating them here
   * means they don't have to be generated ad hoc wherever they are needed.
   */
  get formatted_os_version() {
    return getOSNameVersion(this);
  }

  get days_exposed() {
    return formatDaysExposed(this.create_time);
  }

  get formatted_create_time() {
    return formatDate(this.create_time);
  }

  static fromDTO = (dto: SoftwarePackageDTO) => new SoftwarePackage(dto);
}

export interface SoftwarePayload extends SoftwarePackageDTO {
  readonly group_deferred_until?: null;
}

export interface SoftwareSearchResponseDTO {
  readonly size: number;
  readonly results: readonly SoftwarePackageDTO[];
}

export class SoftwareSearchResponse {
  readonly size: number;

  readonly results: readonly SoftwarePackage[];

  constructor(response: SoftwareSearchResponseDTO) {
    this.size = response.size;
    this.results = response.results.map(SoftwarePackage.fromDTO);
  }
}
