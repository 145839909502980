import {
  ApiClientError,
  AxiosResponseError,
} from '@ax/api-clients-common/models';
import { UnknownFetchError } from '@ax/cache-and-dedupe-vue';
import { tapAsync, Request } from '@ax/function-utils';
import { failure, Response, success } from '@ax/type-utils';
import { AxiosInstance } from 'axios';
import { ConfigLoadingError } from '@ax/data-services-config';
import { MDMAccount, CreateMDMAccountAPIError, MDMAccountDTO } from '../models';
import { fetchMDMClient } from './fetch-mdm-client';
import {
  uploadAPNSCertificate,
  UploadAPNSCertificateError,
} from './upload-apns-certificate';

export interface CreateMDMAccountInput {
  readonly orgId: number;
  readonly appleId: string;
  readonly certificate: File;
  // We need to remove this
  readonly name: string;
}

export type CreateMDMAccountResponse = Response<
  MDMAccount,
  CreateMDMAccountError
>;

export type CreateMDMAccountError =
  | UnknownFetchError
  | ApiClientError<AxiosResponseError>
  | ConfigLoadingError
  | CreateMDMAccountAPIError
  | UploadAPNSCertificateError;

/**
 * Create a macOS patching account for the specified organization
 * @param orgId The id of the organization the account is associated with
 * @param name The name of the patching account
 * @param appleId The apple ID of the user associated with the account
 * */
export function createMDMAccount(
  input: CreateMDMAccountInput,
): Promise<CreateMDMAccountResponse> {
  return fetchMDMClient()
    .then(Request.flatMap(createMDMAccountWithClient(input)))
    .then(
      tapAsync(
        Request.flatMap((account) =>
          uploadAPNSCertificate({
            accountId: account.id,
            certificate: input.certificate,
          }),
        ),
      ),
    );
}

export function createMDMAccountWithClient(input: CreateMDMAccountInput) {
  return (client: AxiosInstance) =>
    client
      .post<MDMAccountDTO>(`/accounts`, input)
      .then(({ data }) => success(new MDMAccount(data)))
      .catch((error) => failure(new CreateMDMAccountAPIError(error)));
}
