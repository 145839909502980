import { Nullable, pipe } from '@ax/function-utils';
import * as REF from '@ax/vue-utils/ref';
import { Ref } from '@vue/composition-api';
import { useGetSoftwareQuery, GetSoftware } from '../queries';
import { useExportCsvUrl } from './use-export-csv-url';
import { useFormattedSoftwareQueryResponse } from './use-formatted-software-query-response';

/**
 * Takes in a `Ref<string | undefined>` representing the current query and returns a
 * `FormattedSoftwareQueryResponse` which contains fields like `totalPackages`,
 * `packages`, `error`, `isLoading`, `isRefreshing`, etc.
 *
 * @example
 * const currentQuery = shallowRef('q=1&...');
 *
 * const {
 *  packages, // Ref<readonly SoftwarePackage[]>
 *  totalPackages, // Ref<number>
 *  isLoading, // Ref<boolean>
 *  error, // Ref<ShapeOfError<GetSoftware> | undefined>
 *  isFailure, // Ref<boolean>
 *  isRefreshing, // Ref<boolean>
 *  exportCsvUrl // Ref<string>
 * } = useSoftware(currentQuery)
 * @param query `Ref<string | undefined>`
 * @returns `FormattedSoftwareQueryResponse`
 */
export function useSoftware(query: Ref<string | undefined>) {
  return {
    ...pipe(
      query,
      REF.map(Nullable.map(GetSoftware.make)),
      useGetSoftwareQuery,
      useFormattedSoftwareQueryResponse,
    ),
    exportCsvUrl: useExportCsvUrl(query),
  };
}
