import {
  ApiClientError,
  AxiosResponseError,
} from '@ax/api-clients-common/models';
import { UnknownFetchError } from '@ax/cache-and-dedupe-core/query-fetcher';
import { defaultCatchHandler } from '@ax/data-services-common/queries';
import { Response, success } from '@ax/type-utils';
import { Request } from '@ax/function-utils';
import { AxiosInstance } from 'axios';
import { ConfigLoadingError } from '@ax/data-services-config';
import { MDMAccount, MDMAccountDTO } from '../models';
import { fetchMDMClient } from './fetch-mdm-client';

export type FetchMDMAccountResponse = Response<
  MDMAccount,
  FetchMDMAccountError
>;

export type FetchMDMAccountError =
  | UnknownFetchError
  | ApiClientError<AxiosResponseError>
  | ConfigLoadingError;

/**
 * Gets the macOS patching account information based on the organization id
 * @param orgId The id of the organization the account is associated with
 * @returns The account information as a promise
 * */
export function fetchMdmAccount(
  orgId: number,
): Promise<FetchMDMAccountResponse> {
  return fetchMDMClient().then(
    Request.flatMap(fetchMdmAccountWithClient(orgId)),
  );
}

/**
 * Given an `orgId` and a mdm `url`, submit a request to get the `MDMAccountInfo`
 * @param orgId
 * @param url
 */
export function fetchMdmAccountWithClient(orgId: number) {
  return (client: AxiosInstance) =>
    client
      .get<MDMAccountDTO>(`/accounts/lookup/${orgId}`)
      .then(({ data }) => success(new MDMAccount(data)))
      .catch(defaultCatchHandler);
}
