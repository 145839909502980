import jwt_decode from 'jwt-decode';

interface PartialAutomoxJwtPayload {
  exp: number;
}

const JWT_EXPIRATION_PADDING_IN_SECONDS = 30;

export function getUnixTimeInSeconds(): number {
  return Math.floor(Date.now() / 1000);
}

export function getExpirationTime(token: string): number {
  const { exp } = jwt_decode<PartialAutomoxJwtPayload>(token);
  return exp;
}

export function getPaddedExpirationTime(token: string): number {
  return getExpirationTime(token) - JWT_EXPIRATION_PADDING_IN_SECONDS;
}

export function jwtIsExpired(token: string): boolean {
  const exp = getPaddedExpirationTime(token);
  return exp > 0 && exp < getUnixTimeInSeconds();
}

export function shouldRefresh(token: string): boolean {
  const exp = getExpirationTime(token);
  const timeToExpireInSeconds = exp - getUnixTimeInSeconds();
  return (
    timeToExpireInSeconds < JWT_EXPIRATION_PADDING_IN_SECONDS &&
    timeToExpireInSeconds > 5
  );
}
