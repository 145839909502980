export enum PolicyTypeName {
  patch = 'patch',
  required_software = 'required_software',
  worklet = 'worklet',
  custom = 'custom',
}

export interface PolicyBaseDTO {
  name: string;
  policy_type_name: PolicyTypeName;
  organization_id: number;
  configuration: PolicyConfigurationDTO;
  schedule_days: number;
  schedule_weeks_of_month: number;
  schedule_months: number;
  schedule_time: string;
  notes: string;
  create_time: string;
  server_groups: number[];
  server_count: number;
}

interface PolicyConfigurationDTO {
  auto_patch?: boolean;
  patch_rule?: string;
  auto_reboot?: boolean;
  notify_user?: boolean;
  include_optional?: boolean;
  notify_reboot_user?: boolean;
  missed_patch_window?: boolean;
  custom_notification_max_delays?: number;
  custom_notification_deferment_periods?: number[];
  filters?: string[];
  filter_type?: PolicyAdvancedLeft;
  advanced_filter?: AdvancedFilterDTO[];
  severity_filter?: string[];
  custom_notification_patch_message?: string;
  custom_notification_reboot_message?: string;
  custom_notification_patch_message_mac?: string;
  custom_notification_reboot_message_mac?: string;
  os_family?: string;
  test_code?: null;
  device_filters?: PolicyDeviceFilterFieldDTO[];
  evaluation_code?: null | string;
  remediation_code?: null | string;
  installation_code?: string;
  device_filters_enabled?: boolean;
  use_scheduled_timezone?: boolean;
  install_deferral_enabled?: boolean;
  notify_deferred_reboot_user?: boolean;
  pending_reboot_deferral_enabled?: boolean;
  custom_pending_reboot_notification_message?: string;
  notify_deferred_reboot_user_message_timeout?: number;
  custom_pending_reboot_notification_max_delays?: number;
  custom_pending_reboot_notification_message_mac?: string;
  notify_deferred_reboot_user_auto_deferral_enabled?: boolean;
  custom_pending_reboot_notification_deferment_periods?: number[];
  package_name?: string;
  package_version?: string;
  refresh_before_remediation?: boolean;
}

interface AdvancedFilterDTO {
  left: PolicyAdvancedLeft;
  right: PolicyAdvancedRight;
  condition: PolicyAdvancedConditionDTO;
}

enum PolicyAdvancedConditionDTO {
  is = 'is',
  is_not = 'is-not',
  less_than_or_equal = 'less-than-or-equal-to',
  greater_than_or_equal = 'greater-than-or-equal-to',
  contains = 'contains',
  does_not_contain = 'does-not-contain',
}

enum PolicyAdvancedLeft {
  patch_source = 'patch-source',
  patch_os = 'patch-os',
  type = 'type',
  display_name = 'display-name',
  severity = 'severity',
  patch_days_old = 'patch-days-old',
}

enum PolicyAdvancedRight {
  windowsupdate = 'windowsupdate',
  microsoft = 'microsoft',
  apple = 'apple',
  oracle = 'oracle',
  mozilla = 'mozilla',
  adobe = 'adobe',
  opera = 'opera',
  windows = 'windows',
  mac = 'mac',
  linux = 'linux',
  application = 'application',
  connectors = 'connectors',
  critical_updates = 'critical-updates',
  definition_updates = 'definition-updates',
  developer_kits = 'developer-kits',
  feature_packs = 'feature-packs',
  guidance = 'guidance',
  security_updates = 'security-updates',
  service_packs = 'service-packs',
  tools = 'tools',
  update_rollups = 'update-rollups',
  updates = 'updates',
  upgrades = 'upgrades',
  other = 'other',
  none = 'none',
  low = 'low',
  medium = 'medium',
  high = 'high',
  critical = 'critical',
}

enum PolicyDeviceFilterOperator {
  is = 'in',
  is_not = 'not_in',
  contains = 'like_any',
  does_not_contain = 'not_like_any',
}

enum PolicyDeviceFilterField {
  tag = 'tag',
  hostname = 'hostname',
  ip_addr = 'ip_addr',
  os_family = 'os_family',
  os_version_id = 'os_version_id',
  organizational_unit = 'organizational_unit',
}

interface PolicyDeviceFilterFieldDTO {
  field: PolicyDeviceFilterField;
  op: PolicyDeviceFilterOperator;
  value: number[] | string[];
}
