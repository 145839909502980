import * as REF from '@ax/vue-utils/ref';
import { Ref } from '@vue/composition-api';
import { SoftwareSearchResponse, SoftwarePackage } from '../models';

/**
 * Extract out the number of total packages indicated in `SoftwareSearchResponse`
 * @param response `SoftwareSearchResponseDTO | undefined`
 * @returns number
 */
function toTotalPackages(response?: SoftwareSearchResponse) {
  return response?.size ?? 0;
}

/**
 * Reactive version of `toTotalPackages`
 */
const useTotalPackages = REF.map(toTotalPackages);

/**
 * Extract out the `SoftwarePackageDTO[]` objects from the response if present,
 * otherwise return an empty array
 * @param response
 * @returns `readonly SoftwarePackageDTO[]`
 */
function toPackages(response?: SoftwareSearchResponse) {
  return response?.results ?? [];
}

/**
 * Reactive version of `toPackages`
 */
const usePackages = REF.map(toPackages);

export interface ReactiveFormattedSoftwareResponse {
  /**
   * The packages returned from the API if present, otherwise
   * an empty `[]`
   */
  readonly packages: Readonly<Ref<readonly SoftwarePackage[]>>;
  /**
   * The total number of packages available
   */
  readonly totalPackages: Readonly<Ref<number>>;
}

/**
 * Maps a `Ref<SoftwareSearchResponseDTO | undefined>` to a `ReactiveFormattedSoftwareData`
 * object
 * @param response `Ref<SoftwareSearchResponseDTO | undefined>`
 * @returns `ReactiveFormattedSoftwareData`
 */
export function useFormattedSoftwareResponse(
  response: Ref<SoftwareSearchResponse | undefined>,
): ReactiveFormattedSoftwareResponse {
  return {
    packages: usePackages(response),
    totalPackages: useTotalPackages(response),
  };
}
