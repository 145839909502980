import { PaginatedResponse } from '@ax/api-clients-common/models';
import { AccountRbacRoleDTO, RoleType } from '../models';
import { getPublicAccountHttpClient } from '../index';

// https://automox-ll.stoplight.io/docs/global-view/branches/Phase%201/account_management.yaml/paths/~1api~1accounts~1%7BaccountId%7D~1rbac-roles/get
export function getRbacRoles(
  type?: RoleType,
): Promise<PaginatedResponse<AccountRbacRoleDTO>> {
  return getPublicAccountHttpClient()
    .get(`/rbac-roles${type ? `?type:equals=${type}` : ''}`)
    .then(({ data }) => data);
}
