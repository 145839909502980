import { PermissionMap } from '@ax/data-services-authorization/business-logic/permissions';
import { PolicyTypeName } from '../models/base-policy-dto';

export function getMappedPermissionByPolicyType(
  policyTypeName: PolicyTypeName,
) {
  switch (policyTypeName) {
    case PolicyTypeName.patch:
      return PermissionMap.PatchPolicy;
    case PolicyTypeName.worklet:
    case PolicyTypeName.custom:
      return PermissionMap.CustomPolicy;
    case PolicyTypeName.required_software:
      return PermissionMap.RequiredSoftwarePolicy;
    default:
      throw new Error('Unable to determine policy type');
  }
}
