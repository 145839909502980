/* START: https://automox.atlassian.net/wiki/spaces/QE/pages/682950711/Device+Status+Redux */
export enum AgentStatuses {
  connected = 'connected',
  disconnected = 'disconnected',
}

export enum DeviceStatuses {
  initializing = 'initializing',
  installing = 'installing',
  needsReboot = 'needs-reboot',
  notReady = 'not-ready',
  ready = 'ready',
  rebooting = 'rebooting',
  refreshing = 'refreshing',
  uninstalling = 'uninstalling',
  working = 'working',
}

export enum PolicyStatuses {
  compliant = 'compliant',
  nonCompliant = 'non-compliant',
  unmanaged = 'unmanaged',
  scheduled = 'scheduled',
}

export class PolicyStatusForDeviceStatus {
  id?: number;

  compliant?: boolean;

  constructor(init: Partial<PolicyStatusForDeviceStatus>) {
    Object.assign(this, init);
  }
}

export class DeviceStatus {
  agent_status?: AgentStatuses;

  device_status?: DeviceStatuses;

  policy_status?: PolicyStatuses;

  policy_statuses?: PolicyStatusForDeviceStatus[];

  constructor(init: Partial<DeviceStatus>) {
    Object.assign(this, init);
  }
}
/* END */
