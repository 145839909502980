import axios, { AxiosInstance } from 'axios';
import { Request } from '@ax/function-utils';
import {
  Environment,
  getConfig,
  ConfigLoadingError,
} from '@ax/data-services-config';
import { Response, success, failure } from '@ax/type-utils';
import { fetchJwt } from '@ax/data-services-authentication/queries';
import { JwtMintingError } from '@ax/data-services-authentication/models';
import { flatMap } from '@ax/function-utils/request';

export const HISTORY_SERVICE_URLS: Record<Environment, string> = {
  dev: 'https://history.dev.automox.com/zones',
  stg: 'https://history.dev.automox.com/zones',
  production: 'https://history.automox.com/zones',
};

/**
 * Gets the history axios client with a generated JWT token set as the auth.
 */
export function getPreAuthedHistoryClient() {
  return fetchJwt().then(
    flatMap((token) =>
      fetchHistoryClient().then(
        flatMap<
          AxiosInstance,
          AxiosInstance,
          ConfigLoadingError | JwtMintingError
        >((client) => {
          client.defaults.headers.common.Authorization = `Bearer ${token}`;
          return Promise.resolve(success(client));
        }),
      ),
    ),
  );
}

/**
 * Generate the history axios client, caching the successful result so that
 * we don't create multiple instances.
 * @returns `Response<AxiosInstance, ConfigLoadingError>`
 */
export const fetchHistoryClient = Request.cacheOnSuccess(() =>
  fetchURL().then(Request.map(makeHistoryClient)),
);

/**
 * Gets the correct history service URL based on the environment from the config.
 * @returns `Promise<Response<string, ConfigLoadingError>>`
 */
function fetchURL(): Promise<Response<string, ConfigLoadingError>> {
  return getConfig()
    .then((config) => success(HISTORY_SERVICE_URLS[config.environment]))
    .catch((e) => failure(new ConfigLoadingError(e)));
}

function makeHistoryClient(baseURL: string) {
  return axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
    },
  });
}
