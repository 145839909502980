import { AxiosResponse } from 'axios';
import { ConsoleAxiosRequestConfig } from '@ax/api-clients-common';
import { BaseHTTPClient } from '@ax/data-services-common/client';
import { defaultCatchHandler } from '@ax/data-services-common/queries';
import { propMap } from '@ax/object-utils';
import { success } from '@ax/type-utils';
import {
  SoftwarePayload,
  SoftwareSearchResponse,
  SoftwareSearchResponseDTO,
} from '../models';

const GET_SOFTWARE_ENDPOINT = '/orgs/self/packages/search';

export const SOFTWARE_ENDPOINT = '/orgs/self/packages';

/**
 * Maps a `AxiosResponse<SoftwareSearchResponseDTO>` into a `Success<SoftwareSearchResponse>`
 */
const axiosResponseToSearchResponse = propMap(
  'data',
  (dto: SoftwareSearchResponseDTO) => success(new SoftwareSearchResponse(dto)),
);

/**
 * Submits an API request for a `SoftwareSearchResponse` with the given `query`.
 * @param query
 * @param config
 * @returns `Response<SoftwareSearchResponse, ApiClientError | UnknownFetchError>`
 */
export function fetchSoftware(
  query: string,
  config: ConsoleAxiosRequestConfig = {},
) {
  return BaseHTTPClient.get<SoftwareSearchResponse>(
    `${GET_SOFTWARE_ENDPOINT}?${query}`,
    config,
  )
    .then(axiosResponseToSearchResponse)
    .catch(defaultCatchHandler);
}

export function patchSoftware(
  id: number,
  orgId: number,
  config: ConsoleAxiosRequestConfig = {},
): Promise<AxiosResponse<void>> {
  return BaseHTTPClient.post(
    `${SOFTWARE_ENDPOINT}/${id}/patch?o=${orgId}`,
    undefined,
    config,
  );
}

export function ignoreSoftware(
  id: number,
  orgId: number,
  payload: SoftwarePayload,
  config: ConsoleAxiosRequestConfig = {},
) {
  return BaseHTTPClient.put<void>(
    `${SOFTWARE_ENDPOINT}/${id}?o=${orgId}&internal=1`,
    payload,
    config,
  );
}

/**
 * Submits an API request to get the `SoftwareSearchResponse` for the software pertaining
 * to a specfic `Policy`.
 * @param query `string`
 * @param config
 * @returns `Response<SoftwareSearchResponse, ApiClientError | UnknownFetchError>`
 */
export function fetchPolicySoftware(
  query: string,
  config: ConsoleAxiosRequestConfig = {},
) {
  return BaseHTTPClient.get<SoftwareSearchResponseDTO>(
    `/software/display_names?${query}`,
    config,
  )
    .then(axiosResponseToSearchResponse)
    .catch(defaultCatchHandler);
}
