export interface GroupedOs {
  id: string;
  value: OS[];
}

export interface OS {
  familyId?: number;
  id?: number;
  name?: string;
}

export enum OSFamilies {
  windows = 'Windows',
  mac = 'Mac',
  linux = 'Linux',
}

export interface OSFamily {
  os_family_id?: number;

  os_family_name?: OSFamilies;

  oses?: Record<number, string> | Record<string, OSForOSVersions>;
}

export interface OSes {
  Linux?: OSFamily;

  Mac?: OSFamily;

  Windows?: OSFamily;
}

export interface OSForOSVersions {
  os_id?: number;

  os_name?: string;

  versions?: Record<string, string>;
}
