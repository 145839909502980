import { SegmentAnalytics } from '@ax/data-services-tracking/analytics';

/**
 * Submit an event to Segment for tracking export csv clicks
 */
export function trackSoftwareExportCSVClick() {
  SegmentAnalytics.track('Click: Export CSV', {
    location: 'Software Page',
  });
}
