import { fromTimestamp, Timestamp } from '@ax/date-time-utils';
import { Copyable } from '@ax/object-utils';
import * as N from '@ax/function-utils/nullable';

export type MDMAccountID = string; // uuid

export interface MDMAccountDTO {
  readonly version: number;
  readonly id: MDMAccountID;
  readonly created: Timestamp;
  readonly updated?: Timestamp;
  readonly deleted?: Timestamp;
  readonly organizationId: string;
  readonly appleId: string;
}

/**
 * Convert a potentially nullable timestamp string into a nullable `Date`
 */
const toNullableDate = N.map(fromTimestamp);

export class MDMAccount extends Copyable<MDMAccountDTO> {
  readonly createdAt = fromTimestamp(this.created);

  readonly updatedAt = toNullableDate(this.created);

  readonly deletedAt = toNullableDate(this.deleted);
}
