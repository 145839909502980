export enum Severity {
  other = 'other',
  unknown = 'unknown',
  none = 'none',
  low = 'low',
  medium = 'medium',
  high = 'high',
  critical = 'critical',
}

export const SEVERITY_ORDER = [
  Severity.unknown,
  Severity.none,
  Severity.low,
  Severity.medium,
  Severity.high,
  Severity.critical,
];
