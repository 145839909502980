import { AxiosResponse } from 'axios';
import {
  PaginatedResponse,
  GlobalPaginationMetadata,
} from '@ax/api-clients-common/models';
import {
  Zone,
  ZoneCreate,
  ZoneUser,
  ZoneUpdate,
  ZoneWithCounts,
  ZoneUserOld,
} from '../models';
import {
  getPublicAccountHttpClient,
  getBespokeAccountHttpClient,
} from '../index';

const ZONES_ENDPOINT = '/zones';

// https://automox-ll.stoplight.io/docs/global-view/branches/Phase%201/account_management.yaml/paths/~1accounts~1%7BaccountId%7D~1zones/get
export function getZones(
  query?: string,
): Promise<PaginatedResponse<ZoneWithCounts, GlobalPaginationMetadata>> {
  return getBespokeAccountHttpClient()
    .get(`${ZONES_ENDPOINT}${query ? `?${query}` : ''}`)
    .then(({ data }) => data);
}

// https://automox-ll.stoplight.io/docs/global-view/branches/Phase%201/account_management.yaml/paths/~1api~1accounts~1%7BaccountId%7D~1zones/get
export function getZonesWithoutUserInfo(
  query?: string,
): Promise<PaginatedResponse<Zone, GlobalPaginationMetadata>> {
  return getPublicAccountHttpClient()
    .get(`${ZONES_ENDPOINT}${query ? `?${query}` : ''}`)
    .then(({ data }) => data);
}

// https://automox-ll.stoplight.io/docs/global-view/branches/Phase%201/account_management.yaml/paths/~1api~1accounts~1%7BaccountId%7D~1zones~1%7BzoneId%7D/get
export function getZone(zoneId: string): Promise<Zone> {
  return getPublicAccountHttpClient()
    .get(`${ZONES_ENDPOINT}/${zoneId}`)
    .then(({ data }) => data);
}

// https://automox-ll.stoplight.io/docs/global-view/branches/Phase%201/account_management.yaml/paths/~1accounts~1%7BaccountId%7D~1zones/post
export function createZone(payload: ZoneCreate): Promise<AxiosResponse> {
  // Returns 201 when successful
  return getBespokeAccountHttpClient().post(`${ZONES_ENDPOINT}`, payload);
}

// https://automox-ll.stoplight.io/docs/global-view/branches/Phase%201/account_management.yaml/paths/~1accounts~1%7BaccountId%7D~1zones~1%7BzoneId%7D/post
export function updateZone(
  zoneId: string,
  payload: ZoneUpdate,
): Promise<AxiosResponse> {
  // Returns 204 No content when successful
  return getBespokeAccountHttpClient().post(
    `${ZONES_ENDPOINT}/${zoneId}`,
    payload,
  );
}

// https://automox-ll.stoplight.io/docs/global-view/branches/Phase%201/account_management.yaml/paths/~1api~1accounts~1%7BaccountId%7D~1zones~1%7BzoneId%7D~1users/get
// (Note: Also includes global admin users)
export function getZoneUsers({
  zoneId,
  limit,
  page,
}: {
  zoneId: string;
  limit?: number;
  page?: number;
}): Promise<PaginatedResponse<ZoneUser, GlobalPaginationMetadata>> {
  return (
    getPublicAccountHttpClient()
      .get(`${ZONES_ENDPOINT}/${zoneId}/users`, { params: { limit, page } })
      .then(({ data }) => data)
      // Once the API is switched to the new pattern (https://automox.atlassian.net/browse/EVG-14) this .then can be removed
      .then((response) => {
        const { data } = response;
        function isOldFormat(
          data: ZoneUser[] | ZoneUserOld[],
        ): data is ZoneUserOld[] {
          return !('user' in data[0]);
        }
        const transformedData = isOldFormat(data)
          ? data.map<ZoneUser>((user) => {
              return {
                user,
                rbac_role: user.zone_rbac_role,
              };
            })
          : data;
        return { ...response, data: transformedData };
      })
  );
}
