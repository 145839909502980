import axios from 'axios';
import { Request } from '@ax/function-utils';
import {
  ConfigLoadingError,
  Environment,
  getConfig,
} from '@ax/data-services-config';
import { Response, success, failure } from '@ax/type-utils';

const MDM_URL: Record<Environment, string> = {
  dev: 'https://mdm.dev.automox-dev.com/api/v0',
  stg: 'https://mdm.dev.automox-dev.com/api/v0',
  production: 'https://mdm.automox.com/api/v0',
};

/**
 * Generate the mdm axios client, caching the successful result so we don't
 * create multiple instances.
 * @returns `Response<AxiosInstance, ConfigLoadingError>`
 */
export const fetchMDMClient = Request.cacheOnSuccess(() =>
  fetchURL().then(Request.map(makeMDMClient)),
);

/**
 * Use to get the `url`for the `mdm-server` for the current environment.
 * @returns `Promise<Response<string, ConfigLoadingError>>`
 */
export function fetchURL(): Promise<Response<string, ConfigLoadingError>> {
  return getConfig()
    .then((config) => success(MDM_URL[config.environment]))
    .catch((e) => failure(new ConfigLoadingError(e)));
}

/**
 * So that we only generate one client
 */
function makeMDMClient(baseURL: string) {
  return axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: false,
  });
}
